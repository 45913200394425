import { Card } from "primereact/card";
import { ResumeIndicators } from "./ResumeIndicators";
import { CampSideInfoResume } from "./CampSideInfoResume";
import { ActionsComponent } from "../ActionsComponent";
import { normalizeName, styleEstado } from "../../utils/estados.uitls";
import { Button } from "primereact/button";

export const CampaignResume = ({
  data,
  clientId,
  handleCalendar,
  loadingButton,
  setConfirmationDialog,
  color,
  cambiarValor,
  setEditPaused,
  handleEditCampaign,
}) => {
  return (
    <Card className="campaign-card-resume">
      <div
        className="campaign-resume-header"
        style={{ backgroundColor: color }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.tipoEncuesta === "Saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="pi pi-sign-in"></i>
          )}
          <span
            style={{
              marginLeft: "8px",
              marginRight: "4px",
              fontWeight: "500",
              wordBreak: "break-word",
            }}
          >
            {data.nombre}
          </span>
        </div>

        <div style={{ display: "flex" }}>
          <Button
            className="calendar-view"
            icon="pi pi-pencil"
            onClick={() => handleEditCampaign(data)}
          />
          <Button
            className="calendar-view"
            icon="pi pi-calendar"
            onClick={() => handleCalendar()}
          />
          <span
            className={
              "customer-badge status-" +
              styleEstado(data.nombreEstado) +
              " text-status"
            }
            style={{ marginRight: "4px" }}
          >
            {normalizeName(data.nombreEstado)}
          </span>

          <ActionsComponent
            data={data}
            loadingButton={loadingButton}
            setConfirmationDialog={setConfirmationDialog}
            cambiarEstado={cambiarValor}
          />
        </div>
      </div>

      <div className="body">
        <div className="row">
          <div className="ficha">
            <div className="text-row">
              <span>
                <small>Creada: </small>
              </span>
              <span>
                <strong>{data.fechaCreada}</strong>
              </span>
            </div>
            {clientId === 0 && (
              <div>
                <span>
                  <small>Cliente: </small>
                </span>
                <span>
                  <strong>{data.user.cliente}</strong>
                </span>
              </div>
            )}

            {data.tipoEncuesta === "Saliente" && (
              <>
                <div>
                  <span>
                    <small>Proveedor: </small>
                  </span>
                  <span>
                    <strong>{data.vendor.name}</strong>
                  </span>
                </div>
                <button className="edit-box" onClick={() => setEditPaused()}>
                  <div className="text-row">
                    <span>
                      <small>Canales: </small>
                    </span>
                    <span>
                      <strong>{data.vendor.channel}</strong>
                    </span>
                  </div>
                  <div className="text-row">
                    <span>
                      <small>CPS: </small>
                    </span>
                    <span>
                      <strong>{data.vendor.cps}</strong>
                    </span>
                  </div>
                  <i className="edit-numbers pi pi-cog"></i>
                </button>
              </>
            )}
          </div>

          {data.tipoEncuesta === "Saliente" && (
            <CampSideInfoResume data={data} />
          )}
        </div>
        <div style={{ height: "10px" }}></div>
        <ResumeIndicators data={data} />
      </div>
    </Card>
  );
};

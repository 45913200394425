import React, { useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import monitoringService from "../../services/monitoring.service";
import { IAsteriskType } from "../../types/asterisk.type";
import { Toast } from "primereact/toast";

export const AsteriskLateral = ({ astActive, astDeact, data }) => {
  const toast = useRef(null);
  const [checked, setChecked] = useState(data.active);

  const switchAsterisk = (data) => {
    const updatePartial: IAsteriskType = {
      id: null,
      serverName: null,
      ip: null,
      port: null,
      user: null,
      password: null,
      context: null,
      exten: null,
      orden: null,
      prefix: null,
      pathTTs: null,
      active: !data.active,
    };
    monitoringService
      .UpdateAsterisk(data.id, updatePartial)
      .then((response: any) => {
        try {
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: `Asterisk ${data.serverName}`,
            life: 3000,
          });
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el cambio",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const handleSwitch = (e) => {
    setChecked(e);
    switchAsterisk(data);
  };

  return (
    <div
      className="asterisk-lat-container"
      style={{ backgroundColor: checked ? astActive : astDeact }}
    >
      <Toast ref={toast} />
      <div className="text">
        <span style={{ fontSize: "16px" }}>{data.serverName}</span>
        <div>
          <small>({data.ip})</small>
        </div>
      </div>
      <InputSwitch checked={checked} onChange={(e) => handleSwitch(e.value)} />
    </div>
  );
};

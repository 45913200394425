import { HiSpeakerWave } from "react-icons/hi2";

function FrontContent({ data }) {
  return (
    <div className="camp-cont">
      {data.tipoEncuesta === "Saliente" ? (
        <Front data={data} />
      ) : (
        <FrontEntrante data={data} />
      )}
    </div>
  );
}

export default FrontContent;

const FrontEntrante = ({ data }) => {
  return (
    <div style={{ marginTop: "8px" }}>
      <div className="row">
        <Box
          title="Llamadas recibidas"
          bnum={data.llamadasRecibidas.toLocaleString("es-AR")}
          snum=""
          width="50"
          audio={false}
        ></Box>
        <Box
          title="Teléfono"
          bnum={data.telefono}
          snum=""
          width="50"
          audio={false}
        ></Box>
      </div>
    </div>
  );
};

const Front = ({ data }) => {
  return (
    <div style={{ marginTop: "8px" }}>
      <div className="row">
        <Box
          title="Llamados Error"
          bnum={data.totalErrores.toLocaleString("es-AR")}
          snum=""
          width="33"
          audio={false}
        ></Box>
        <Box
          title="Llamados OK"
          bnum={data.totalLlamadasCorrectas.toLocaleString("es-AR")}
          snum=""
          width="33"
          audio={false}
        ></Box>
        <Box
          title="Sistema"
          bnum={data.totalLlamadas.toLocaleString("es-AR")}
          snum=""
          width="33"
          audio={false}
        ></Box>
      </div>
      <div className="row quarter-container">
        <Box
          title="NSPC"
          bnum={data.porcentajeNoSePudoContactar.toLocaleString("es-AR") + "%"}
          snum={data.totalNoSePudoContactar.toLocaleString("es-AR")}
          width="25"
          audio={false}
        ></Box>
        <Box
          title="Sin respuesta"
          bnum={data.porcentajeBlancos.toLocaleString("es-AR") + "%"}
          snum={data.totalBlancos.toLocaleString("es-AR")}
          width="25"
          audio={true}
        ></Box>
        <Box
          title="Contestadores"
          bnum={data.porcentajeContestadores.toLocaleString("es-AR") + "%"}
          snum={data.totalContestadores.toLocaleString("es-AR")}
          width="25"
          audio={true}
        ></Box>
        <Box
          title="Contactabilidad"
          bnum={data.porcentajeCasosExitosos.toLocaleString("es-AR") + "%"}
          snum={data.casosExitosos.toLocaleString("es-AR")}
          width="25"
          audio={true}
        ></Box>
      </div>
      <div className="row">
        <Box
          title="Inválidos"
          bnum={data.porcentajeInvalidos.toLocaleString("es-AR") + "%"}
          snum={data.registrosInvalidos.toLocaleString("es-AR")}
          width="33"
          audio={false}
        ></Box>
        <Box
          title="Listos"
          bnum={data.porcentajeValidos.toLocaleString("es-AR") + "%"}
          snum={data.registrosValidosListos.toLocaleString("es-AR")}
          width="33"
          audio={false}
        ></Box>
        <Box
          title="No listos"
          bnum={(100 - data.porcentajeValidos).toLocaleString("es-AR") + "%"}
          snum={data.registrosValidosNoListos.toLocaleString("es-AR")}
          width="33"
          audio={false}
        ></Box>
      </div>
    </div>
  );
};

const Box = ({ title, bnum, snum, width, audio }) => {
  let color;
  let backgroundColor;
  let border;

  switch (title) {
    case "Contestadores":
      color = "#8A5340";
      backgroundColor = "#FEEDAF";
      border = "transparent";
      break;
    case "NSPC":
      color = "#694382";
      backgroundColor = "#ECCFFF";
      border = "transparent";
      break;
    case "Contactabilidad":
      color = "#256029";
      backgroundColor = "#C8E6C9";
      border = "transparent";
      break;
    case "Sin respuesta":
      color = "#23547B";
      backgroundColor = "#B3E5FC";
      border = "transparent";
      break;
    case "Llamados OK":
      color = "#4CAF50";
      border = "#dee2e6";
      break;
    case "Llamados Error":
      color = "#F4511E";
      border = "#dee2e6";
      break;
    default:
      color = "#888";
      backgroundColor = "#fff";
      border = "#dee2e6";
  }

  const handleAudio = (e) => {
    e.stopPropagation();
    // console.log('audio');
  };

  let className;

  switch (width) {
    case "25":
      className = "cont quarter";
      break;
    case "50":
      className = "cont medio";
      break;
    case "33":
      className = "cont tercio";
      break;
    default:
      className = "cont";
      break;
  }

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor,
        borderColor: border,
      }}
    >
      {audio && (
        <button
          onClick={(e) => handleAudio(e)}
          style={{
            border: "0",
            background: "transparent",
            padding: "0",
            margin: "0",
            cursor: "pointer",
          }}
        >
          <HiSpeakerWave
            style={{
              position: "absolute",
              bottom: "8px",
              left: "8px",
              color: "#444",
            }}
          />
        </button>
      )}
      <span className="title" style={{ color: color }}>
        {title}
      </span>
      <span className="bnum" style={{ color: color }}>
        {bnum}
      </span>
      <span className="snum">{snum}</span>
    </div>
  );
};

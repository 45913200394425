import React from "react";
import { layout } from "../../utils/colors";

export const Asterisk = ({ data }) => {
  const color = layout.astActive;

  return (
    <div className="ast-cont" style={{ backgroundColor: color }}>
      <span>{data.serverName}</span>
    </div>
  );
};

import { Button, Dialog } from "primereact";

export const ConfirmRestart = ({
  handleRestart,
  editRestart,
  hideEditRestart,
}) => {
  const editDialogFooter = (
    <>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => handleRestart()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideEditRestart}
      />
    </>
  );

  return (
    <Dialog
      visible={editRestart}
      style={{ width: "440px", maxWidth: "95%" }}
      header="Reiniciar"
      modal
      footer={editDialogFooter}
      onHide={hideEditRestart}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>
          ¿Estas seguro de que quieres reiniciar los productores y consumidores?
        </span>
      </div>
    </Dialog>
  );
};

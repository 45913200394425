import http from "../common/components/http-common";

export const restartProdCon = (userName, viewName, viewFunction) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.post<any>("/campaignCallHub/v1/restart", config);
};

export function ResumeIndicators({ data }) {
  return (
    <div className="camp-cont">
      {data.tipoEncuesta === "Saliente" ? (
        <Resume data={data} />
      ) : (
        <ResumeEntrante data={data} />
      )}
    </div>
  );
}

const Resume = ({ data }) => {
  return (
    <div className="resume-cont">
      <Box
        title="NSPC"
        value={data.porcentajeNoSePudoContactar.toLocaleString("es-AR") + "%"}
        number={data.totalNoSePudoContactar.toLocaleString("es-AR")}
      ></Box>
      <Box
        title="Sin respuesta"
        value={data.porcentajeBlancos.toLocaleString("es-AR") + "%"}
        number={data.totalBlancos.toLocaleString("es-AR")}
      ></Box>
      <Box
        title="Contestadores"
        value={data.porcentajeContestadores.toLocaleString("es-AR") + "%"}
        number={data.totalContestadores.toLocaleString("es-AR")}
      ></Box>
      <Box
        title="Contactabilidad"
        value={data.porcentajeCasosExitosos.toLocaleString("es-AR") + "%"}
        number={data.casosExitosos.toLocaleString("es-AR")}
      ></Box>
    </div>
  );
};

const ResumeEntrante = ({ data }) => {
  return (
    <div className="resume-cont">
      <Box
        title="Llamadas recibidas"
        value={data.llamadasRecibidas.toLocaleString("es-AR")}
        number={""}
      ></Box>
      <Box title="Teléfono" value={data.telefono} number={""}></Box>
    </div>
  );
};

const Box = ({ title, value, number }) => {
  let color;
  let backgroundColor;
  let border;

  switch (title) {
    case "Contestadores":
      color = "#8A5340";
      backgroundColor = "#FEEDAF";
      border = "transparent";
      break;
    case "NSPC":
      color = "#694382";
      backgroundColor = "#ECCFFF";
      border = "transparent";
      break;
    case "Contactabilidad":
      color = "#256029";
      backgroundColor = "#C8E6C9";
      border = "transparent";
      break;
    case "Sin respuesta":
      color = "#23547B";
      backgroundColor = "#B3E5FC";
      border = "transparent";
      break;
    case "Llamados OK":
      color = "#4CAF50";
      border = "#dee2e6";
      break;
    case "Llamados Error":
      color = "#F4511E";
      border = "#dee2e6";
      break;
    default:
      color = "#888";
      backgroundColor = "rgb(241, 243, 244)";
      border = "#dee2e6";
  }

  let _title;
  switch (title) {
    case "Contestadores":
      _title = "Contest.";
      break;
    case "Sin respuesta":
      _title = "Sin resp.";
      break;
    case "Contactabilidad":
      _title = "Contact.";
      break;

    default:
      _title = title;
      break;
  }
  return (
    <div
      className="box"
      style={{ backgroundColor: backgroundColor, borderColor: border }}
    >
      <span className="value" style={{ color: color }}>
        {value}
      </span>
      <span className="title" style={{ color: color }}>
        {_title}
      </span>
      <span className="mini-number">{number}</span>
    </div>
  );
};

import http from "../common/components/http-common";
import { IAsteriskType } from "../types/asterisk.type";

class MonitoringService {
  getIndicators(encuesta?: null) {
    let parameter;
    if (encuesta) {
      parameter = {
        encuesta: encuesta,
      };
    } else {
      parameter = {
        excludedStatus: "4",
      };
    }

    let config = {
      params: parameter,
    };
    return http.get<any>("/encuestas/indicators", config);
  }

  getBatches() {
    return http.get<any>("/v1/configuration");
  }

  getAsterisks() {
    return http.get<any>("/v1/asterisk/");
  }

  getConsumers() {
    return http.get<any>("/indicator/consumers/");
  }

  UpdateAsterisk(id, data) {
    return http.patch<IAsteriskType>("/v1/asterisk/" + id, data);
  }

  getIndicatorsSummary(users, fecha, clientId?) {
    const parameter = {
      users: users,
      // type: type || null,
      date: fecha,
      ...(clientId !== null ? { clientId: clientId } : {}),
    };

    let config = {
      params: parameter,
      paramsSerializer: { indexes: null },
    };

    return http.get<any>("/indicator/summary", config);
  }

  getIndicatorsClient(users, status, fecha) {
    const parameter = {
      users: users,
      status: status,
      date: fecha,
    };

    let config = {
      params: parameter,
      paramsSerializer: { indexes: null },
    };

    return http.get<any>("/indicator/client", config);
  }

  getCalendar(users, status, fecha) {
    const parameter = {
      users: users,
      status: status,
      date: fecha,
    };

    let config = {
      params: parameter,
      paramsSerializer: { indexes: null },
    };

    return http.get<any>("/indicator/calendar", config);
  }
}

export default new MonitoringService();

import React from "react";
import { blocks } from "../../utils/colors";

export const RowTabla = ({ title, value }) => {
  let color;
  let backgroundColor;

  switch (title) {
    case "Contestadores":
      color = blocks.contestadores.color;
      backgroundColor = blocks.contestadores.backgorundColor;
      break;
    case "NSPC":
      color = blocks.npc.color;
      backgroundColor = blocks.npc.backgorundColor;
      break;
    case "Exitosos":
      color = blocks.exitosos.color;
      backgroundColor = blocks.exitosos.backgorundColor;
      break;
    case "Blancos":
      color = blocks.blancos.color;
      backgroundColor = blocks.blancos.backgorundColor;
      break;
    default:
      color = "#888";
      backgroundColor = "#f1f3f4";
  }

  return (
    <div
      className="row-tabla"
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      <span>{title}</span>
      <span>
        <strong>{value}</strong>
      </span>
    </div>
  );
};

import React, { useState } from "react";
import { BsPlayCircle, BsPauseCircle, BsStopCircle } from "react-icons/bs";
import { Button } from "primereact/button";
export const ActionsComponent = ({
  data,
  loadingButton,
  setConfirmationDialog,
  cambiarEstado,
}) => {
  const handleBoton = (estado) => {
    setConfirmationDialog(false);
    cambiarEstado("estado", estado);
  };

  const handleConfirmation = () => {
    setConfirmationDialog(true);
  };

  return (
    <div className="controls">
      {data.nombreEstado === "ACTIVA" && (
        <Button
          icon={<BsPauseCircle />}
          onClick={() => handleBoton("PAUSADA")}
          disabled={loadingButton}
          className="action"
        />
      )}
      {data.nombreEstado === "PAUSADA" && (
        <Button
          icon={<BsPlayCircle />}
          onClick={() => handleBoton("ACTIVA")}
          disabled={loadingButton}
          className="action"
        />
      )}

      <Button
        icon={<BsStopCircle />}
        onClick={() => handleConfirmation()}
        disabled={loadingButton}
        className="action"
      />
    </div>
  );
};

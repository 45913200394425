import React from "react";

export const CampSideInfo = ({ data }) => {
  return (
    <div className="side-container">
      <div className="box">
        <span className="bnum">
          {data.totalPendienteLlamar.toLocaleString("es-AR")}
        </span>
        <span className="title">Pend. base</span>
        <div className="sep"></div>
        <span className="bnum">
          {data.registrosPendientesDeRangoActivo.toLocaleString("es-AR")}
        </span>
        <span className="title">Pend. rango</span>
      </div>

      <div className="box white">
        <span className="bnum">
          {data.totalRegistrosConUnLlamado.toLocaleString("es-AR")}
        </span>
        <span className="title">Negocio</span>
        <div className="sep"></div>
        <span className="bnum">
          {data.registrosProcesados.toLocaleString("es-AR")}
        </span>
        <span className="title">Procesados</span>
      </div>
    </div>
  );
};

export const layout = {
  barColor: "#FFFFFF",
  ind1: "#90A4AE",
  ind2: "#90A4AE",
  ind3: "#90A4AE",
  ind4: "#90A4AE",
  proc: "#00B8D4",
  astActive: "#81C784",
  astDeact: "#EF5350",
};

export const blocks = {
  contestadores: {
    color: "#8A5340",
    backgroundColor: "#FEEDAF",
    border: "transparent",
  },
  npc: {
    color: "#694382",
    backgroundColor: "#ECCFFF",
    border: "transparent",
  },
  exitosos: {
    color: "#256029",
    backgroundColor: "#C8E6C9",
    border: "transparent",
  },
  blancos: {
    color: "#23547B",
    backgroundColor: "#B3E5FC",
    border: "transparent",
  },
  llamadosOk: {
    color: "#4CAF50",
    backgroundColor: "#dee2e6",
  },
  llamadosError: {
    color: "#F4511E",
  },
};

export const tipoCamp = {
  activa: "#0062b7",
  espera: "#00b8d4",
  pausada: "#90a4ae",
};

import React from "react";
import { RowTabla } from "./RowTabla";

export const CampSideInfoResume = ({ data }) => {
  return (
    <div className="tabla">
      <RowTabla
        title="Pend. base"
        value={data.totalPendienteLlamar.toLocaleString("es-AR")}
      ></RowTabla>
      <RowTabla
        title="Pend. rango"
        value={data.registrosPendientesDeRangoActivo.toLocaleString("es-AR")}
      ></RowTabla>
      <RowTabla
        title="Negocio"
        value={data.totalRegistrosConUnLlamado.toLocaleString("es-AR")}
      ></RowTabla>
      <RowTabla
        title="Listos"
        value={data.registrosValidosListos.toLocaleString("es-AR")}
      ></RowTabla>
      <RowTabla
        title="Sistema"
        value={data.totalLlamadas.toLocaleString("es-AR")}
      ></RowTabla>
    </div>
  );
};

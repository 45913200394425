import React, { useEffect, useState } from "react";
import { RefreshComp } from "./RefreshComp";
import MonitoringService from "../../services/monitoring.service";
import { Button } from "primereact/button";
import { ConsumerLateral } from "./ConsumerLateral";
import { GiButtonFinger } from "react-icons/gi";

export const LateralBar = ({
  getStart,
  intervalo,
  setIntervalo,
  summary,
  handleCalendar,
  pauseRefresh,
  confirmRestart,
  viewRestart,
}) => {
  const [consumers, setConsumers] = useState(null);

  const [batchTotal, setBatchTotal] = useState(0);
  const [batchOcupados, setBatchOcupados] = useState(0);
  const [batchCaidos, setBatchCaidos] = useState(0);

  useEffect(() => {
    getData();

    const intervalId = setInterval(getData, intervalo);
    return () => clearInterval(intervalId);
  }, []);

  const getData = () => {
    MonitoringService.getBatches()
      .then((response: any) => {
        try {
          setBatches(response.data.batchs_configuration);
        } catch (err) {}
      })
      .catch((e: Error) => {});

    MonitoringService.getConsumers()
      .then((response: any) => {
        try {
          setConsumers(response.data);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const setBatches = (batches) => {
    setBatchTotal(batches.length);
    setBatchCaidos(batches.filter((b) => b.status === "ERROR").length);
    setBatchOcupados(batches.filter((b) => b.status === "PROCESANDO").length);
  };

  return (
    <div className="lateral-bar">
      {viewRestart && (
        <Button
          className="all-calendar in-filter"
          style={{
            color: "rgb(239, 83, 80)",
            right: "148px",
            fontSize: "1.4em",
          }}
          icon={<GiButtonFinger />}
          onClick={() => confirmRestart()}
        />
      )}

      <Button
        className="all-calendar"
        icon="pi pi-calendar"
        onClick={() => handleCalendar()}
      />
      <RefreshComp
        getStart={getStart}
        intervalo={intervalo}
        setIntervalo={setIntervalo}
        pauseRefresh={pauseRefresh}
      />

      <div className="box-container">
        <BoxIndicator label="Registros" number={summary.records} />
        <BoxIndicator label="Campañas" number={summary.campaigns} />
        <BoxIndicator label="Llamados" number={summary.calls} />
        <BoxIndicator label="Llamados Error" number={summary.errorCalls} />
      </div>

      <div className="proc-container">
        <ProcIndicator label="Batch Total" number={batchTotal} />
        <ProcIndicator label="Batch Ocup." number={batchOcupados} />
        <ProcIndicator label="Batch Caídos" number={batchCaidos} />
      </div>

      <div className="pills-cont">
        {consumers &&
          consumers.map((c, index) => <ConsumerLateral key={index} data={c} />)}
      </div>
    </div>
  );
};

const BoxIndicator = ({ label, number }) => {
  return (
    <div className="div-box">
      <div className="div-number">{number}</div>
      <div className="div-titulo">{label}</div>
    </div>
  );
};

const ProcIndicator = ({ label, number }) => {
  return (
    <div className="div-proc">
      <div className="div-number">{number}</div>
      <div className="div-titulo">{label}</div>
    </div>
  );
};

import http from "../common/components/http-common";

export const ObtenerUsuarioById = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>("/v1/users/" + id, config);
};

export const ObtenerHijos = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/users/${id}/childrens/`, config);
};

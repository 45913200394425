import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { normalizeName, styleEstado } from "../../utils/estados.uitls";
import { ActionsComponent } from "../ActionsComponent";
import { CampSideInfo } from "./CampSideInfo";
import { Asterisk } from "./Asterisk";
import FrontContent from "./FrontContent";

export const Campaign = ({
  data,
  clientId,
  handleCalendar,
  handleChart,
  loadingButton,
  setConfirmationDialog,
  color,
  cambiarValor,
  setEditPaused,
  handleEditCampaign,
}) => {
  return (
    <Card className="campaign-card">
      <div className="campaign-header" style={{ backgroundColor: color }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.tipoEncuesta === "Saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="pi pi-sign-in"></i>
          )}
          <span
            style={{
              marginLeft: "8px",
              marginRight: "4px",
              fontWeight: "500",
              wordBreak: "break-word",
            }}
          >
            {data.nombre}
          </span>
        </div>

        <div className="controls-cont">
          <Button
            className="calendar-view"
            icon="pi pi-pencil"
            onClick={() => handleEditCampaign(data)}
          />
          <Button
            className="calendar-view"
            icon="pi pi-calendar"
            onClick={() => handleCalendar()}
          />
          <Button
            className="calendar-view"
            icon="pi pi-chart-bar"
            onClick={() => handleChart()}
          />
          <span
            className={
              data.nombreEstado &&
              `customer-badge status-${styleEstado(
                data.nombreEstado
              )} text-status`
            }
          >
            {normalizeName(data.nombreEstado)}
          </span>

          <ActionsComponent
            data={data}
            loadingButton={loadingButton}
            setConfirmationDialog={setConfirmationDialog}
            cambiarEstado={cambiarValor}
          />
        </div>
      </div>

      <div className="campaign-body">
        <div className="body-top">
          <div className="ficha">
            <div className="text-row">
              <span>
                <small>Creada: </small>
              </span>
              <span>
                <strong>{data.fechaCreada}</strong>
              </span>
            </div>
            {clientId === 0 && (
              <div>
                <span>
                  <small>Cliente: </small>
                </span>
                <span>
                  <strong>{data.user.cliente}</strong>
                </span>
              </div>
            )}
            {data.tipoEncuesta === "Saliente" && (
              <>
                <div className="text-row">
                  <span>
                    <small>Proveedor: </small>
                  </span>
                  <span>
                    <strong>{data.vendor.name}</strong>
                  </span>
                </div>
                <div className="text-row">
                  <span>
                    <small>Cola llamados: </small>
                  </span>
                  <span>
                    <strong>{data.vendor.queueName}</strong>
                  </span>
                </div>
                <button className="edit-box" onClick={() => setEditPaused()}>
                  <div className="text-row">
                    <span>
                      <small>Canales: </small>
                    </span>
                    <span>
                      <strong>{data.vendor.channel}</strong>
                    </span>
                  </div>
                  <div className="text-row">
                    <span>
                      <small>CPS: </small>
                    </span>
                    <span>
                      <strong>{data.vendor.cps}</strong>
                    </span>
                  </div>
                  <i className="edit-numbers pi pi-cog"></i>
                </button>
              </>
            )}
          </div>

          {data.tipoEncuesta === "Saliente" && <CampSideInfo data={data} />}
        </div>

        <FrontContent data={data} />
      </div>
      {data.tipoEncuesta === "Saliente" && (
        <div className="footer" style={{ backgroundColor: color }}>
          <div className="ast-row">
            {data.asteriskInUse.map((a) => (
              <Asterisk key={a.id} data={a} />
            ))}
          </div>
          <div className="inner-footer"></div>
        </div>
      )}
    </Card>
  );
};

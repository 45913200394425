import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";

export const RefreshComp = ({
  intervalo,
  setIntervalo,
  getStart,
  pauseRefresh,
}) => {
  useEffect(() => {
    let timeoutId = null;

    const startTimeout = () => {
      if (!pauseRefresh && intervalo !== 0) {
        timeoutId = setTimeout(() => {
          getStart();
          startTimeout();
        }, intervalo);
      }
    };
    startTimeout();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [intervalo, pauseRefresh]);

  const options = [
    { name: "Off", value: 0 },
    { name: "5s", value: 5000 },
    { name: "10s", value: 10000 },
    { name: "30s", value: 30000 },
    { name: "1m", value: 60000 },
  ];

  const handleSelect = (value) => {
    setIntervalo(value);
  };

  return (
    <div className="refresh-comp">
      <div className="refresh-cont">
        <i
          className="pi pi-sync"
          style={{ fontSize: "1.2rem", marginRight: "10px", color: "#ced4da" }}
        ></i>

        <Dropdown
          value={intervalo}
          onChange={(e) => handleSelect(e.value)}
          options={options}
          optionLabel="name"
        />
      </div>
    </div>
  );
};

import http from "../common/components/http-common";

export const getDataChart = (id, vistaAvanzada) => {
  const _endpoint = vistaAvanzada
    ? `/v2/campaign/dashboardGraphs/${id}/allStatuses`
    : `/v2/campaign/dashboardGraphs/${id}`;

  let config = {
    headers: {},
  };
  return http.get<any>(_endpoint, config);
};

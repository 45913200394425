import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaSkull } from "react-icons/fa";

export const ConsumerLateral = ({ data }) => {
  const [color, setColor] = useState(null);
  const [caveira, setCaveira] = useState(false);

  useEffect(() => {
    defineColor();
  }, [data]);

  const defineColor = () => {
    if (data.alive && data.idEncuesta && data.idEncuesta !== "") {
      setColor("rgba(252,161,48,.4)");
      setCaveira(false);
    }

    if (data.alive && (!data.idEncuesta || data.idEncuesta === "")) {
      setColor("rgba(73,204,144,.4)");
      setCaveira(false);
    }

    if (!data.alive && (!data.idEncuesta || data.idEncuesta === "")) {
      setColor("rgba(249,62,62,.4)");
      setCaveira(false);
    }

    if (!data.alive && data.idEncuesta && data.idEncuesta !== "") {
      setColor("rgba(249,62,62,.4)");
      setCaveira(true);
    }
  };

  const formatData = (data) => {
    const expresionRegular = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

    if (expresionRegular.test(data)) {
      const fechaFormated = moment(data).format("DD-MM-YY HH:mm");
      return fechaFormated;
    }
    return data;
  };

  const options = [
    { value: "nombreEncuesta", icon: "pi pi-megaphone" },
    { value: "idEncuesta", icon: "pi pi-info-circle" },
    { value: "fechaHora", icon: "pi pi-calendar" },
  ];

  const optionMap = options.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const isOptionDisabled = (optionValue) => {
    const optionData = data[optionValue];
    return optionData === null || optionData === "";
  };

  const renderContent = () => {
    if (selectedOption) {
      const { value } = selectedOption;
      return (
        <div className="pill-cont">
          <div
            className="pill"
            style={{
              backgroundColor: color,
            }}
          >
            {formatData(data[value])}
          </div>
          {caveira && (
            <div className="caveira">
              <FaSkull />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="consumer-lat-container"
      style={{
        backgroundColor: color,
      }}
    >
      <div className="consumer-header">
        <div
          className="buttons"
          style={{
            backgroundColor: color,
          }}
        >
          {options.map((option) => (
            <button
              key={option.value}
              className={
                selectedOption && selectedOption.value === option.value
                  ? "selected"
                  : ""
              }
              onClick={() => setSelectedOption(optionMap[option.value])}
              disabled={isOptionDisabled(option.value)}
            >
              <i className={option.icon}></i>
            </button>
          ))}
        </div>
        <div className="numbers">
          <div className="number">
            <span className="label">Port </span>
            <span className="value">{data.port}</span>
          </div>
          <div className="number">
            <span className="label">Active calls </span>
            <span className="value">{data.activeCalls}</span>
          </div>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

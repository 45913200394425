import React from "react";
import { AsteriskLateral } from "./lateralBar/AsteriskLateral";

export const Footer = ({ asterisks }) => {
  return (
    <div className="footer-asterisks">
      {asterisks.map((ast) => (
        <AsteriskLateral
          key={ast.id}
          data={ast}
          astActive="#81C784"
          astDeact="#EF5350"
        />
      ))}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { tipoCamp } from "../utils/colors";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { editarCampaign } from "../services/campaign.service";
import { viewTypes } from "../common/types/views.type";
import { Panel } from "./_slidePanel/Panel";
import { EditDialog } from "./EditDialog";
import MonitoringService from "../services/monitoring.service";
import { Campaign } from "./campaign/Campaign";
import { CalendarPanelContain } from "./calendar/CalendarPanelContain";
import { CampaignResume } from "./campaignRes/CampaignResume";
import { ChartPanelContain } from "./chart/ChartPanelContain";

export const CampaignBase = ({
  id,
  clientId,
  tipoCard,
  pauseRefresh,
  setPauseRefresh,
  rend,
}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState(null);

  const getCampaign = () => {
    MonitoringService.getIndicators(id)
      .then((response: any) => {
        try {
          setData(response.data.indicators[0]);
          setTypeAndColor(response.data.indicators[0]);
        } catch (err) {
          throw err;
        }
      })
      .catch((e: Error) => {
        throw e;
      });
  };

  useEffect(() => {
    !pauseRefresh && getCampaign();
  }, [rend]);

  /** */
  const [color, setColor] = useState(null);
  const [type, setType] = useState(null);

  const setTypeAndColor = (response) => {
    setType(response.tipoEncuesta);

    switch (response.estado) {
      case "0": //Pendiente por procesar
        setColor(tipoCamp.espera);
        break;
      case "1": //Activa
        setColor(tipoCamp.activa);
        break;
      case "2": //Pausada
        setColor(tipoCamp.pausada);
        break;
      case "3": //Finalizando
      case "4": //Finalizada
      case "5": //En espera
      case "6": //Abortada
      case "7": //Bloqueando
      case "8": //Bloqueada
      default:
        setColor(tipoCamp.pausada);
    }
  };

  const { vnMonitoring: viewName, vfVerMonitoring: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  /**/
  const initErrors = {
    cps: null,
    channel: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };
  const [submitted, setSubmitted] = useState(false);
  /**/

  const hideConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const cambiarValor = (name, value) => {
    setLoadingButton(true);

    const _data = {
      id: data._id,
      [name]: value,
    };

    editCampaign(_data);
  };

  const editCampaign = (_data) => {
    setData(null);

    editarCampaign(_data, type.toLowerCase(), userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          getCampaign();
          hideConfirmationDialog();
          hideEditDialog();
          resetErrors();
          setLoadingButton(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {});
  };
  /** */
  const [editDialog, setEditDialog] = useState(false);

  const hideEditDialog = () => {
    resetErrors();
    setEditDialog(false);
    setPauseRefresh(false);
  };

  const handleEditCampaign = (data) => {
    sessionStorage.setItem("editCampaignId", data._id);
    sessionStorage.setItem("editCampaignType", data.tipoEncuesta.toLowerCase());
    window.location.href = "/listado";
  };

  const setEditPaused = () => {
    setEditDialog(true);
    setPauseRefresh(true);
  };

  /** */
  const handleChart = () => {
    setTimeout(() => {
      setChartOpen(true);
    }, 8);
  };

  const [chartOpen, setChartOpen] = useState(false);
  const closeChart = () => {
    setChartOpen(false);
  };

  /** */
  const handleCalendar = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };
  /** */

  const Base = ({ tipoCard }) => {
    const commonProps = {
      data,
      clientId,
      handleCalendar,
      handleChart,
      loadingButton,
      setConfirmationDialog,
      color,
      cambiarValor,
      setEditPaused,
      handleEditCampaign,
    };
    return (
      <div>
        {tipoCard === "full" && <Campaign {...commonProps} />}
        {tipoCard === "resumen" && <CampaignResume {...commonProps} />}
      </div>
    );
  };

  const SkeletonBase = ({ tipoCard }) => {
    return (
      <div className={`skeleton-card ${tipoCard}`}>
        <div className="skeleton-header"></div>

        <div className="skeleton-body">
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line" style={{ height: "32px" }}></div>

          <div className="skeleton-row">
            <div className="skeleton-cont"></div>
            <div className="skeleton-cont"></div>
            <div className="skeleton-cont"></div>
            <div className="skeleton-cont"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        <>
          <Base tipoCard={tipoCard} />

          <ConfirmationDialog
            confirmationDialog={confirmationDialog}
            hideConfirmationDialog={hideConfirmationDialog}
            data={data}
            cambiarEstado={cambiarValor}
            loadingButton={loadingButton}
          />

          {data.tipoEncuesta === "Saliente" && (
            <EditDialog
              editDialog={editDialog}
              hideEditDialog={hideEditDialog}
              cambiarValor={cambiarValor}
              data={data}
              errors={errors}
              setErrors={setErrors}
              submitted={submitted}
              setSubmitted={setSubmitted}
              pauseRefresh={pauseRefresh}
            />
          )}

          <Panel isOpen={isOpen} closePanel={closePanel}>
            <CalendarPanelContain data={data} closePanel={closePanel} />
          </Panel>

          <Panel isOpen={chartOpen} closePanel={closeChart}>
            <ChartPanelContain closePanel={closeChart} data={data} />
          </Panel>
        </>
      ) : (
        <SkeletonBase tipoCard={tipoCard} />
      )}
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { LateralBar } from "./lateralBar/LateralBar";
import { TabView, TabPanel } from "primereact/tabview";
import MonitoringService from "../services/monitoring.service";
import { FilterPanel } from "./FilterPanel";
import { useMediaQuery } from "react-responsive";
import { ObtenerHijos } from "../services/user.service";
import moment from "moment";
import { viewTypes } from "../common/types/views.type";
import { ImConfused } from "react-icons/im";
import { Panel } from "./_slidePanel/Panel";
import { CalendarAllPanelContain } from "./calendar/CalendarAllPanelContain";
import { CampaignBase } from "./CampaignBase";
import { Footer } from "./Footer";
import { ShowView } from "../common/components/ShowView";
import { ConfirmRestart } from "./ConfirmRestart";
import { restartProdCon } from "../services/prodcon.service";

export const Monitoring = () => {
  useEffect(() => {
    getStart();
  }, []);

  const _status = [
    "ACTIVA",
    "PAUSADA",
    "EN_ESPERA",
    "CONFIGURANDO_CAMPANNA",
    "PENDIENTE_POR_PROCESAR",
    "FINALIZANDO",
    "BLOQUEADA",
    "BLOQUEANDO",
  ];

  const getStart = () => {
    getUser(userId, userName, viewName, viewFunction);
  };

  const [calendar, setCalendar] = useState(null);
  const {
    vnMonitoring: viewName,
    vfVerMonitoring: viewFunction,
    vfRestartProdCon,
  } = viewTypes;
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("user");

  const [summary, setSummary] = useState({
    campaigns: "",
    records: "",
    calls: "",
    errorCalls: "",
  });

  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const [intervalo, setIntervalo] = useState(10000);
  const [pauseRefresh, setPauseRefresh] = useState(false);

  const [tabs, setTabs] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const clientIdRef = useRef(0);
  const [rend, setRend] = useState(null);
  const [asterisks, setAsterisks] = useState(null);

  const getUser = (userId, userName, viewName, viewFunction) => {
    ObtenerHijos(userId, userName, viewName, viewFunction).then(
      (response: any) => {
        try {
          armarArrayUser(response.data);
        } catch (err) {
          const error = err as { message?: string };
        }
      }
    );
  };

  const armarArrayUser = (children) => {
    let _users = [];
    _users.push(userId);
    const fecha = moment().format("YYYY-MM-DD HH:mm");

    const users = _users.concat(children);

    goSummary(users, fecha);
    goClient(users, fecha);
    goCalendar(users, fecha);
    goAsterisks();
  };

  const goAsterisks = () => {
    MonitoringService.getAsterisks()
      .then((response: any) => {
        try {
          setAsterisks(response.data.asterisks);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const goCalendar = (users, fecha) => {
    MonitoringService.getCalendar(users, _status, fecha)
      .then((response: any) => {
        try {
          setCalendar(response.data);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const goSummary = (users, fecha) => {
    const _clientId = clientIdRef.current === 0 ? null : clientIdRef.current;

    MonitoringService.getIndicatorsSummary(users, fecha, _clientId)
      .then((response: any) => {
        try {
          const _summary = {
            campaigns: response.data.campaigns,
            records: response.data.records,
            calls: response.data.calls,
            errorCalls: response.data.errorCalls,
          };

          setSummary(_summary);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const goClient = (users, fecha) => {
    MonitoringService.getIndicatorsClient(users, _status, fecha)
      .then((response: any) => {
        try {
          handleTabs(response.data);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const modifyArray = (tabs) => {
    function agregarTotales(tabs) {
      return tabs.map((item) => {
        const campaigns = item.campaigns;
        const total = campaigns.length;
        const activas = campaigns.filter(
          (campaign) => campaign.status === "ACTIVA"
        ).length;
        const pausadas = campaigns.filter(
          (campaign) => campaign.status === "PAUSADA"
        ).length;

        return {
          ...item,
          total,
          activas,
          pausadas,
        };
      });
    }

    function concatenarCampaigns(tabs) {
      let campaignsConcatenados = [];

      tabs.forEach((elemento) => {
        campaignsConcatenados = campaignsConcatenados.concat(
          elemento.campaigns
        );
      });

      return campaignsConcatenados;
    }

    let _arrayFinal;

    if (tabs.length > 0) {
      const modifiedCampaigns = concatenarCampaigns(tabs);

      const _arrayConActivas = [
        {
          client: "ACTIVAS",
          clientId: 0,
          campaigns: modifiedCampaigns,
        },
        ...tabs,
      ];

      _arrayFinal = agregarTotales(_arrayConActivas);
    } else {
      setCampaign([]);
    }

    var __arrayFinal = _arrayFinal.filter(function (element, index) {
      return index !== 0 || element.activas !== 0;
    });

    clientIdRef.current =
      clientIdRef.current === 0
        ? __arrayFinal[0].clientId
        : clientIdRef.current;
    return __arrayFinal;
  };

  const handleTabs = (_tabs) => {
    const modifiedArray = modifyArray(_tabs);
    setTabs(modifiedArray);
    handleTabChange(null, modifiedArray);
  };

  const [vista, setVista] = useState("0");

  const handleView = (value) => {
    setVista(value.id);
  };

  const handleTabChange = (index?, ftabs?: null) => {
    const _tabs = ftabs ? ftabs : tabs;

    if (index !== null) {
      clientIdRef.current = _tabs[index].clientId;
      setCampaign(null);
      getStart();
    }

    armarVista(ftabs);
  };

  const checkClientId = (array, clientId) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].clientId === clientId) {
        return true;
      }
    }
    return false;
  };

  const armarVista = (ftabs?: null) => {
    const _tabs = ftabs ? ftabs : tabs;
    const existeClientId = checkClientId(_tabs, clientIdRef.current);

    if (existeClientId) {
      const _rend = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
      setRend(_rend);

      const clientId = clientIdRef.current;
      const _campaigns =
        _tabs.find((item) => item.clientId === clientId)?.campaigns || [];

      setCampaign(_campaigns);
    } else {
      handleTabChange(0);
    }
  };

  const EmptyMessage = () => (
    <span style={{ display: "flex", alignItems: "center" }}>
      <span style={{ marginRight: "8px", fontSize: "1rem" }}>
        SIN RESULTADOS
      </span>
      <span>
        <ImConfused />
      </span>
    </span>
  );

  const CustomTabHeader = ({ data }) => {
    return (
      <div className="tab-header">
        <span style={{ fontWeight: "bold" }}>
          {data.client} {data.clientId === 0 ? data.activas : data.total}
        </span>
        {data.clientId !== 0 && (
          <span className="bubbles">
            {data.activas > 0 && (
              <div className="bubble activa">{data.activas}</div>
            )}
            {data.pausadas > 0 && (
              <div className="bubble pausada">{data.pausadas}</div>
            )}
          </span>
        )}
      </div>
    );
  };

  /** */
  const handleCalendar = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };
  /** */

  const confirmRestart = () => {
    setEditRestart(true);
  };

  const handleRestart = () => {
    restartProdCon(userName, viewName, vfRestartProdCon)
      .then((response: any) => {
        try {
          hideEditRestart();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {});
  };
  /** */
  const viewRestart = ShowView(vfRestartProdCon, viewName);
  const [editRestart, setEditRestart] = useState(false);

  const hideEditRestart = () => {
    setEditRestart(false);
  };
  /** */

  return (
    <div className="monitoring">
      <div className="monitoring-container">
        <div className="central-container">
          {isMobile ? (
            <div className="single-panel">
              {tabs && (
                <FilterPanel
                  vista={vista}
                  handleView={handleView}
                  tabs={tabs}
                  handleTabChange={handleTabChange}
                  clientId={clientIdRef.current}
                  handleCalendar={handleCalendar}
                  confirmRestart={confirmRestart}
                  viewRestart={viewRestart}
                />
              )}
              {!tabs ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ opacity: ".4", margin: "1rem" }}
                ></i>
              ) : tabs.length > 0 ? (
                campaign ? (
                  campaign.map((c) =>
                    (c.status === "ACTIVA" && clientIdRef.current === 0) ||
                    clientIdRef.current !== 0 ? (
                      <CampaignBase
                        key={c.campaignId}
                        id={c.campaignId}
                        clientId={clientIdRef.current}
                        tipoCard={vista === "0" ? "resumen" : "full"}
                        pauseRefresh={pauseRefresh}
                        setPauseRefresh={setPauseRefresh}
                        rend={rend}
                      />
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem", opacity: ".4", margin: "1rem" }}
                  ></i>
                )
              ) : (
                <div style={{ padding: "1.5rem", fontSize: "1.2rem" }}>
                  <EmptyMessage />
                </div>
              )}
            </div>
          ) : (
            <TabView
              activeIndex={
                tabs &&
                tabs.findIndex((tab) => tab.clientId == clientIdRef.current)
              }
              onTabChange={(e) => handleTabChange(e.index)}
            >
              {!tabs ? (
                <TabPanel
                  header={<i className="pi pi-spin pi-spinner"></i>}
                ></TabPanel>
              ) : tabs.length > 0 ? (
                tabs.map((tab) => (
                  <TabPanel
                    key={tab.clientId}
                    header={<CustomTabHeader data={tab} />}
                  >
                    <div className="panel-div">
                      {campaign && campaign ? (
                        campaign.map((c) =>
                          clientIdRef.current === 0 ? (
                            c.status === "ACTIVA" && (
                              <CampaignBase
                                key={c.campaignId}
                                id={c.campaignId}
                                clientId={clientIdRef.current}
                                tipoCard="resumen"
                                pauseRefresh={pauseRefresh}
                                setPauseRefresh={setPauseRefresh}
                                rend={rend}
                              />
                            )
                          ) : (
                            <CampaignBase
                              key={c.campaignId}
                              id={c.campaignId}
                              clientId={tab.clientId}
                              tipoCard="full"
                              pauseRefresh={pauseRefresh}
                              setPauseRefresh={setPauseRefresh}
                              rend={rend}
                            />
                          )
                        )
                      ) : (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{
                            fontSize: "2rem",
                            opacity: ".4",
                            margin: "1rem",
                          }}
                        ></i>
                      )}
                    </div>
                  </TabPanel>
                ))
              ) : (
                <TabPanel header={<EmptyMessage />}></TabPanel>
              )}
            </TabView>
          )}
        </div>

        {summary && (
          <LateralBar
            getStart={getStart}
            intervalo={intervalo}
            setIntervalo={setIntervalo}
            pauseRefresh={pauseRefresh}
            summary={summary}
            handleCalendar={handleCalendar}
            confirmRestart={confirmRestart}
            viewRestart={viewRestart}
          />
        )}
      </div>

      {asterisks && <Footer asterisks={asterisks} />}

      {calendar && (
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <CalendarAllPanelContain
            closePanel={closePanel}
            calendar={calendar}
          />
        </Panel>
      )}

      <ConfirmRestart
        handleRestart={handleRestart}
        editRestart={editRestart}
        hideEditRestart={hideEditRestart}
      />
    </div>
  );
};

import http from "../common/components/http-common";

export const editarCampaign = (
  data,
  type,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.patch<any>(`/v1/campaign/${type}/${data.id}`, data, config);
};

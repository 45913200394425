import React, { useEffect, useState } from "react";
import { Button, Dialog } from "primereact";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export const EditDialog = ({
  data,
  editDialog,
  hideEditDialog,
  cambiarValor,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  pauseRefresh,
}) => {
  const [cps, setCps] = useState(data.vendor.cps);
  const [channel, setChannel] = useState(data.vendor.channel);

  useEffect(() => {
    if (!pauseRefresh) {
      setCps(data.vendor.cps);
      setChannel(data.vendor.channel);
    }
  }, [data]);

  useEffect(() => {
    submitted && validateForm();
  }, [cps, channel]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (
      cps === null ||
      parseInt(cps) < 1 ||
      parseInt(cps) > 30 ||
      isNaN(parseInt(cps))
    ) {
      newErrors.cps = "El valor debe estar entre 1 y 30";
      valid = false;
    } else {
      newErrors.cps = null;
    }

    if (
      channel === null ||
      parseInt(channel) < 1 ||
      parseInt(channel) > 500 ||
      isNaN(parseInt(channel))
    ) {
      newErrors.channel = "El valor debe estar entre 1 y 500";
      valid = false;
    } else {
      newErrors.channel = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const editDialogFooter = (
    <>
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => handleChange()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideEditDialog}
      />
    </>
  );

  const handleChange = () => {
    setSubmitted(true);

    if (validateForm()) {
      const configuracionVendor = {
        cps,
        channel,
      };
      cambiarValor("configuracionVendor", configuracionVendor);
    }
  };

  const headerTemplate = (nombre) => (
    <span>
      Editar{" "}
      <small style={{ color: "#888", fontWeight: "400" }}>{nombre}</small>
    </span>
  );

  return (
    <Dialog
      visible={editDialog}
      style={{ width: "440px", maxWidth: "95%" }}
      header={headerTemplate(data.nombre)}
      modal
      footer={editDialogFooter}
      onHide={hideEditDialog}
    >
      <div className="mini-form">
        <div className="field">
          <label htmlFor="canales">Canales</label>
          <InputText
            value={channel}
            type="number"
            min={1}
            max={300}
            onChange={(e) => setChannel(e.target.value)}
            placeholder="Canales"
            className={classNames({
              "p-invalid": submitted && errors.channel,
            })}
          />
          {errors.channel && (
            <small className="p-error block">{errors.channel}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="cps">CPS</label>
          <InputText
            value={cps}
            type="number"
            min={1}
            max={20}
            onChange={(e) => setCps(e.target.value)}
            placeholder="CPS"
            className={classNames({
              "p-invalid": submitted && errors.cps,
            })}
          />
          {errors.cps && <small className="p-error block">{errors.cps}</small>}
        </div>
      </div>
    </Dialog>
  );
};

import React, { useState } from "react";
import { Toolbar } from "primereact";
import { Button } from "primereact/button";
import { ChartDetail } from "./ChartDetail";
import { normalizeName, styleEstado } from "../../utils/estados.uitls";

export const ChartPanelContain = ({ data, closePanel }) => {
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="panel-title">{data.nombre}</span>
        <span
          className={
            "customer-badge status-" +
            styleEstado(data.nombreEstado) +
            " text-status"
          }
          style={{ marginLeft: "8px" }}
        >
          {normalizeName(data.nombreEstado)}
        </span>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <ChartDetail id={data._id} />
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Toolbar } from "primereact";
import { Button } from "primereact/button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/es";

export const CalendarAllPanelContain = ({ calendar, closePanel }) => {
  const colores = [
    "#FBD7D2",
    "#CAE7E8",
    "#FFF8C5",
    "#C5CAE8",
    "#BDDEF6",
    "#F1EBDF",
    "#FEE0B1",
    "#DCEAC8",
  ];

  const localizer = momentLocalizer(moment);
  const [eventos, setEventos] = useState(null);

  useEffect(() => {
    convertirAEventosParaCalendar(calendar);
  }, []);

  const setColores = (eventos) => {
    const coloresAsignados = {};
    const eventosConColores = [];

    for (const evento of eventos) {
      const { title } = evento;
      if (!coloresAsignados[title]) {
        coloresAsignados[title] =
          colores[eventosConColores.length % colores.length];
      }

      const eventoConColor = { ...evento, color: coloresAsignados[title] };
      eventosConColores.push(eventoConColor);
    }

    return eventosConColores;
  };

  const convertirAEventosParaCalendar = (calendar) => {
    const eventos = [];

    calendar.forEach((evento) => {
      const { campaign, horarios } = evento;

      horarios.forEach((horario) => {
        const { date, rangos } = horario;

        rangos.forEach((rango) => {
          const fstart = `${date.substring(0, 10)}T${rango.from}`;
          const end = `${date.substring(0, 10)}T${rango.to}`;

          eventos.push({
            title: campaign,
            start: new Date(fstart),
            end: new Date(end),
          });
        });
      });
    });
    setEventos(setColores(eventos));
  };

  const getEventStyle = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        color: "#495057",
      },
    };
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Calendario</div>;
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      {eventos && (
        <Calendar
          style={{ width: "100%" }}
          localizer={localizer}
          events={eventos}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          views={["day", "week"]}
          defaultView="day"
          step={30}
          timeslots={2}
          eventPropGetter={getEventStyle}
          defaultDate={eventos.length > 0 ? eventos[0].start : new Date()}
        />
      )}
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};

import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { GiButtonFinger } from "react-icons/gi";

export const FilterPanel = ({
  vista,
  handleView,
  tabs,
  handleTabChange,
  clientId,
  handleCalendar,
  confirmRestart,
  viewRestart,
}) => {
  const [selectedView, setSelectedView] = useState(vista);

  const handleTab = (value) => {
    handleTabChange(tabs.findIndex((obj) => obj.clientId === value.clientId));
  };

  const view = [
    { icon: "pi pi-stop", id: "0" },
    { icon: "pi pi-th-large", id: "1" },
  ];
  const justifyTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  const handleVista = (value) => {
    handleView(value);
    setSelectedView(value);
  };

  return (
    <div className="filter-panel">
      <div className="filter-card">
        <i
          className="pi pi-filter"
          style={{ fontSize: "1.1rem", paddingTop: "1px", color: "#ced4da" }}
        ></i>

        <div className="buttons">
          {viewRestart && (
            <Button
              className="all-calendar in-filter"
              style={{
                color: "rgb(239, 83, 80)",
                fontSize: "1.4em",
              }}
              icon={<GiButtonFinger />}
              onClick={() => confirmRestart()}
            />
          )}

          <Button
            className="all-calendar in-filter"
            icon="pi pi-calendar"
            onClick={() => handleCalendar()}
          />
          <Dropdown
            value={
              tabs && tabs[tabs.findIndex((tab) => tab.clientId == clientId)]
            }
            onChange={(e) => handleTab(e.value)}
            options={tabs}
            optionLabel="client"
            placeholder="Cliente"
            className="select"
          />

          <SelectButton
            value={view && view.find((v) => v.id === vista)}
            onChange={(e) => handleVista(e.value)}
            itemTemplate={justifyTemplate}
            optionLabel="value"
            options={view}
          />
        </div>
      </div>
    </div>
  );
};
